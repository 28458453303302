<form [formGroup]="form">
    <div *ngIf="pdf === true" class="form-row">
        <div class="form-group col">
            Please answer questions below to finish the process.
        </div>
    </div>
    <div *ngIf="pdf === true" class="form-row pb-3">
        <div class="col">
            1. Are you importing a <b>NEW</b>, fully signed paper contract?
        </div>
        <div class="col-auto">
            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="Q1"
                (change)="Q1_OnChange()">
                <label ngbButtonLabel class="btn-outline-primary">
                    <input ngbButton type="radio" [value]="true"> Yes
                </label>
                <label ngbButtonLabel class="btn-outline-primary">
                    <input ngbButton type="radio" [value]="false"> No
                </label>
            </div>
        </div>
    </div>
    <div [hidden]="q1.value != false" class="form-row">
        <div class="form-group col-12">
            <span>
                {{ (exp ? 'Please select a contract you wish to copy this file to' :
                'Please select a contract you wish to upload to') }}
            </span>
            <ng-select #select formControlName="ContractId" [items]="contracts | async" bindLabel="Number" autofocus
                bindValue="ContractId" groupBy="ContractType" placeholder="Search by Address or Name..."
                [editableSearchTerm]="false" [virtualScroll]="true" (change)="contract_OnChange($event);"
                [searchFn]="customSearchFn">
                <ng-template ng-label-tmp let-item="item">
                    <div><span *ngIf="item.ContractType === ct.Buyer">
                            {{ item.ContractOwners | owners : false }}</span></div>
                    <div><span *ngIf="item.ContractType !== ct.Buyer">
                            {{ item.Prop | address }}</span></div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                    <div><span *ngIf="item.ContractType === ct.Buyer">
                            <span [ngOptionHighlight]="search">{{ item.ContractOwners | owners : false }}</span> -
                            <small><b class="text-capitalize" [ngClass]="cfu.getContractSignClass(item.Status)">
                                    {{item | status}}
                                </b></small></span>
                    </div>
                    <div><span *ngIf="item.ContractType !== ct.Buyer">
                            <span [ngOptionHighlight]="search">{{ item.Prop | address }}</span> -
                            <small><b class="text-capitalize" [ngClass]="cfu.getContractSignClass(item.Status)">
                                    {{item | status}}
                                </b></small></span>
                    </div>
                    <small *ngIf="item.ContractType === ct.Listing">
                        {{item.Prop | price }} |
                        {{ item.ContractOwners | owners : true }} <br>
                    </small>
                    <small *ngIf="item.ContractType === ct.Offer">Buyer: {{ item.ContractOwners | owners : false }}
                        <br></small>
                    <small *ngIf="item.ContractType === ct.Offer">Seller: {{ item.ContractOwners | owners : true }}
                        <br></small>
                </ng-template>
                <ng-template ng-optgroup-tmp let-item="item">
                    {{ct[item.ContractType] || 'Unnamed group'}}
                </ng-template>
            </ng-select>
        </div>
    </div>
    <app-attachment-select-paper-action [hidden]="q1.value != false" [contractActions]="contractActions"
        (action)="paperActions_OnChange($event)">
    </app-attachment-select-paper-action>
    <div [hidden]="q1.value != true" class="form-row">
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="Q2"
            (change)="Q2_OnChange()">
            <label [hidden]="IsSellerOnly()" ngbButtonLabel class="btn-outline-primary">
                <input ngbButton type="radio" [value]="ct.Buyer">{{ types[0] }}
            </label>
            <label ngbButtonLabel class="btn-outline-primary">
                <input ngbButton type="radio" [value]="ct.Listing">{{ types[1] }}
            </label>
            <label [hidden]="IsSellerOnly()" ngbButtonLabel class="btn-outline-primary">
                <input ngbButton type="radio" [value]="ct.Offer"> {{ types[2] }}
            </label>
        </div>
    </div>
</form>